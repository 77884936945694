// fetchAdditionalDetails.jsx
// Works for both production & testing
import axios from "axios";
import Swal from "sweetalert2";
import { setCachedPropertyDetail, getCachedPropertyDetail, normalizeKey } from "../utils/cache.js";
import { normalizeAddress } from "../utils/addressUtils.js";

export const fetchAdditionalDetails = async (zpid) => {
  // Ensure zpid is a valid string
  zpid = String(zpid).trim();
  console.log('fetchAdditionalDetails called with zpid:', zpid);

  if (!zpid) {
    console.error('Invalid or missing zpid:', zpid);
    throw new Error('ZPID must be a valid string and cannot be empty');
  }

  // 1) Check cache
  const normalizedZpid = normalizeKey(zpid);
  let cachedDetail = getCachedPropertyDetail(normalizedZpid);
  if (cachedDetail) {
    console.log(`Cache hit for zpid ${normalizedZpid}. Retrieved from cache:`, cachedDetail);
    // Ensure the cached detail has a valid zpid
    if (cachedDetail.zpid) {
      return cachedDetail;
    } else {
      console.warn('Cached data is missing zpid, forcing re-fetch:', cachedDetail);
    }
  }

  console.log(`Cache miss for zpid ${normalizedZpid}. Fetching from API...`);

  // 2) Decide which endpoint to use based on environment
  const isProduction = process.env.NODE_ENV === 'production';

  let apiUrl = '';
  let requestConfig = {};

  if (isProduction) {
    // Production: call your serverless endpoint
    if (window.location.hostname.includes('vercel.app')) {
      apiUrl = `https://${window.location.hostname}/api/propertyDetails`;
    } else {
      apiUrl = 'https://www.spearrealestategroup.com/api/propertyDetails';
    }

    // We'll request ?zpid=XYZ from your serverless function
    requestConfig = {
      method: 'GET',
      url: apiUrl,
      params: { zpid: normalizedZpid },
      headers: {
        'Content-Type': 'application/json',
      }
    };

  } else {
    // Testing / dev: call Zillow’s RapidAPI directly
    const host = 'zillow-com1.p.rapidapi.com';
    const key = process.env.REACT_APP_API_KEY_RAPID_API_KEY;

    apiUrl = `https://${host}/property`;

    requestConfig = {
      method: 'GET',
      url: apiUrl,
      params: { zpid: normalizedZpid },
      headers: {
        'x-rapidapi-key': key,
        'x-rapidapi-host': host
      }
    };
  }

  let attempt = 0;
  const maxAttempts = 1;
  while (attempt < maxAttempts) {
    try {
      console.log(`Attempt ${attempt + 1}: Fetching from ${apiUrl} with zpid=${normalizedZpid}`);
      const response = await axios.request(requestConfig);
      const data = response.data;

      // 3) Validate the response
      if (!data || !data.zpid) {
        throw new Error('Invalid data received from API');
      }
      console.log(`Successfully fetched details from API for zpid: ${normalizedZpid}`);

      // 4) Cache the result by zpid & normalized address
      if (!data.address) {
        console.warn('API data missing address field:', data);
      } else {
        const normalizedAddr = normalizeAddress(
          `${data.address.streetAddress} ${data.address.city} ${data.address.state} ${data.address.zipcode} USA`
        );
        setCachedPropertyDetail(normalizeKey(normalizedAddr), data);
      }

      setCachedPropertyDetail(normalizedZpid, data);
      return data;

    } catch (error) {
      attempt++;
      console.error(`Error fetching details for zpid ${normalizedZpid}:`, error);
      // Optionally do a retry/backoff if you want. For now, one attempt is enough.
    }
  }

  console.error(`Error fetching details for zpid ${normalizedZpid} after multiple attempts.`);
  return null;
};
