// fetchAdditionalDetailsByAddress.jsx
// Works in both production & testing
import axios from "axios";
import Swal from "sweetalert2";
import { normalizeAddress } from "../utils/addressUtils.js";
import { setCachedPropertyDetail, getCachedPropertyDetail, normalizeKey } from "../utils/cache.js"; 

export const fetchAdditionalDetailsByAddress = async (address) => {
  console.log("fetchAdditionalDetailsByAddress called with:", address);

  if (!address || typeof address !== "string") {
    throw new Error("Address is required to fetch details.");
  }

  // 1) Normalize & check cache
  const normalizedAddress = normalizeAddress(address);
  let cachedDetail = getCachedPropertyDetail(normalizedAddress);
  if (cachedDetail) {
    console.log("Cache hit for address:", normalizedAddress);
    return cachedDetail;
  }

  console.log("Cache miss for address:", normalizedAddress);

  // Decide which endpoint to use based on environment
  const isProduction = process.env.NODE_ENV === "production";

  // In production, call your serverless function at /api/propertyDetails
  // or the custom domain if not on vercel.
  // In testing (dev), call the Zillow RapidAPI endpoint directly.
  let apiUrl = "";
  let requestConfig = {}; // We'll define this in the if/else

  if (isProduction) {
    // --- Production environment: call your serverless endpoint
    if (window.location.hostname.includes("vercel.app")) {
      apiUrl = `https://${window.location.hostname}/api/propertyDetails`;
    } else {
      apiUrl = "https://www.spearrealestategroup.com/api/propertyDetails";
    }

    requestConfig = {
      method: "GET",
      url: apiUrl,
      params: { address: normalizedAddress },
      headers: {
        "Content-Type": "application/json",
      },
    };

  } else {
    // --- Testing / Development environment: call Zillow RapidAPI
    const host = "zillow-com1.p.rapidapi.com";
    const key = process.env.REACT_APP_API_KEY_RAPID_API_KEY;

    apiUrl = `https://${host}/property`;
    requestConfig = {
      method: "GET",
      url: apiUrl,
      params: { address: normalizedAddress },
      headers: {
        "x-rapidapi-key": key,
        "x-rapidapi-host": host,
      },
    };
  }

  let attempt = 0;
  const maxAttempts = 1;
  const baseDelay = 500; // ms
  const maxDelay = 8000; // ms

  while (attempt < maxAttempts) {
    try {
      // optional backoff if you want multiple attempts
      await new Promise(resolve =>
        setTimeout(resolve, Math.min(maxDelay, baseDelay * (2 ** attempt)))
      );
      console.log(`Attempt ${attempt + 1}: Fetching from ${apiUrl}`);

      const response = await axios.request(requestConfig);
      const data = response.data;

      // 2) Validate response
      if (!data || Object.keys(data).length === 0) {
        console.warn(`Empty API response for address: ${address}`);
        return null;
      }

      // The production serverless returns { zpid, address, ... }
      // The Zillow API returns { zpid, address, ... }
      // So in either case, we check for .zpid
      if (!data.zpid || !data.address) {
        console.error("Invalid or incomplete data structure:", data);
        return null;
      }

      console.log("Successfully fetched details for address:", address);

      // 3) Cache the response by both address & zpid
      const zpidKey = normalizeKey(data.zpid);
      setCachedPropertyDetail(zpidKey, data);
      setCachedPropertyDetail(normalizedAddress, data);

      return data;
    } catch (error) {
      console.error(
        `Attempt ${attempt + 1}: Error fetching details for address ${address}:`,
        error
      );
      attempt++;
    }
  }

  console.error(
    `Error fetching additional details for ${address} after multiple attempts.`
  );
  return null;
};
