// utils/addressUtils.js
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/addressUtils.js

const addressMappings = {
    "st": "street",
    "rd": "road",
    "ave": "avenue",
    "blvd": "boulevard",
    "ln": "lane",
    "dr": "drive",
    "ct": "court",
    "cir": "circle",
    "pl": "place",
    "ter": "terrace",
    "pkwy": "parkway",
    "sq": "square",
    "trl": "trail",
    "hwy": "highway",
    "way": "way",
    "or": "oregon"
};

export const normalizeAddress = (address) => {
    if (!address) return '';

    return address
        .toLowerCase()
        .replace(/,/g, '') // Remove commas
        .replace(/\./g, '') // Remove periods
        .replace(/#/g, '%23') // Replace hash symbol with '%23' (URL encoding)
        .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
        .split(' ')
        .map((word, index) => {
            // Ensure that numeric street names (e.g., "3rd") aren't confused with street abbreviations
            if (index === 1 && /^\d+(st|nd|rd|th)$/i.test(word)) {
                return word; // Don't map these to street
            }
            return addressMappings[word] || word; // Expand abbreviations
        })
        .join(' ')
        .trim();
};

export const isAddress = (query) => {
    if (!query) return false;
    const trimmedQuery = query.trim();
  
    // Must start with a number to be considered an address.
    if (!/^\d+/.test(trimmedQuery)) return false;
  
    // Define state abbreviations for US states.
    const stateAbbreviations = new Set([
      "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID",
      "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS",
      "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK",
      "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV",
      "WI", "WY"
    ]);
  
    // Also check if it's a two-word query like "Portland OR"
    const words = trimmedQuery.split(/\s+/);
    if (words.length === 2 && stateAbbreviations.has(words[1].toUpperCase())) {
      return false;
    }
  
    // Ensure it has a street number followed by a street name.
    return /^\d+\s+.+/.test(trimmedQuery);
  };
  