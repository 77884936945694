// Firebase.js
// /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/firebase/Firebase.js

import { getApps, initializeApp } from "firebase/app";
import Swal from "sweetalert2";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updatePassword,
  updateEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  verifyBeforeUpdateEmail,
} from "firebase/auth";
import {
  getFirestore,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getAnalytics, logEvent, isSupported } from "firebase/analytics";
import { getInstallations, deleteInstallations } from "firebase/installations";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAsJh710EITju6cpdVGaVMRXZAeIViQHZU",
  authDomain: "sreg-login.firebaseapp.com",
  projectId: "sreg-login",
  storageBucket: "sreg-login.appspot.com",
  messagingSenderId: "758307286071",
  appId: "1:758307286071:web:184450fdcd348c732e23b2",
  measurementId: "G-H7LX2G6N2R",
};

// Initialize Firebase (only once)
const app = getApps().length ? getApps()[0] : initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();

let analytics = null;

const initializeAnalytics = async () => {
  if (!navigator.onLine) {
    console.warn("Skipping Firebase Analytics initialization because the application is offline.");
    return; // ✅ Prevent unnecessary warning
  }

  try {
    const supported = await isSupported();
    if (!supported) {
      console.warn("Firebase Analytics is not supported in this environment.");
      return;
    }
    analytics = getAnalytics(app);
    console.log("Firebase Analytics initialized successfully.");
  } catch (error) {
    console.error("Failed to initialize Firebase Analytics:", error);
  }
};

// Run initialization only when online
if (navigator.onLine) {
  initializeAnalytics();
}


// Function to clean up Firebase Installations if offline
const cleanupInstallations = async () => {
  if (!navigator.onLine) {
    console.warn("Application is offline. Skipping Firebase installations cleanup.");
    return; 
  }

  try {
    const installations = getInstallations(app);
    await deleteInstallations(installations);
    console.log("Firebase installations cleared successfully.");
  } catch (error) {
    console.error("Failed to delete Firebase installations:", error);
  }
};

// Run cleanup only when online
if (navigator.onLine) {
  cleanupInstallations();
}


// Log events safely
const logEventSafe = (eventName, params) => {
  if (analytics) {
    logEvent(analytics, eventName, params);
  } else {
    console.warn(`Skipping event log: ${eventName}, Firebase Analytics is not initialized.`);
  }
};

// Example log functions
export const logSaveHomeEvent = (userId, homeId) => {
  logEventSafe("save_home", { home_id: homeId, user_id: userId });
};

export const logLoginEvent = (method) => {
  logEventSafe("login", { method });
};

// Send a verification email
export const sendVerificationEmail = async (email) => {
  const user = auth.currentUser;

  if (!user) {
    throw new Error("No user signed in");
  }

  try {
    await verifyBeforeUpdateEmail(user, email);
  } catch (error) {
    console.error("Error sending verification email:", error);
    throw error;
  }
};

// Update the email only if it has been verified
export const updateUserEmail = async (newEmail) => {
  const user = auth.currentUser;

  if (!user) {
    throw new Error("No user signed in");
  }

  if (!user.emailVerified) {
    throw new Error("Please verify the new email before changing email.");
  }

  try {
    // Use the modular updateEmail function instead of calling updateEmail on the user object.
    await updateEmail(user, newEmail);
  } catch (error) {
    console.error("Error updating email:", error);
    throw error;
  }
};

// Change the user's password (with event logging)
export const changeUserPassword = async (oldPassword, newPassword) => {
  const user = auth.currentUser;

  if (!user || !user.email) {
    Swal.fire("Error!", "No user is currently signed in.", "error");
    return;
  }

  try {
    if (typeof oldPassword !== "string" || typeof newPassword !== "string") {
      throw new Error("Passwords must be strings.");
    }

    const credential = EmailAuthProvider.credential(user.email, oldPassword);
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);

    Swal.fire("Success!", "Your password has been updated.", "success");

    // Log the password change event
    analytics.then((analyticsInstance) => {
      if (analyticsInstance) {
        logEvent(analyticsInstance, "change_password", { user_id: user.uid });
      }
    });
  } catch (error) {
    console.error("Error updating password:", error);
    let errorMessage = "Failed to update password. Please try again.";

    if (error.code === "auth/invalid-credential") {
      errorMessage = "The old password is incorrect. Please try again.";
    } else if (error.code === "auth/weak-password") {
      errorMessage = "The new password is too weak. Please choose a stronger password.";
    } else if (error.code === "auth/too-many-requests") {
      errorMessage =
        "Too many failed attempts. Please try again later or contact us for assistance.";
    } else if (error.code === "auth/requires-recent-login") {
      errorMessage = "Please log in again and try to update your password.";
    }

    Swal.fire("Error!", errorMessage, "error");
  }
};

// Saved Searches (with event logging)
export const saveSearch = async (userId, searchUrl) => {
  const userRef = doc(db, "UserAccounts", userId);

  try {
    await runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userRef);

      if (!userDoc.exists()) {
        throw new Error("User document does not exist");
      }

      const newSearch = {
        searchUrl,
        timestamp: new Date().toISOString(),
      };

      transaction.update(userRef, {
        savedSearches: arrayUnion(newSearch),
      });

      analytics.then((analyticsInstance) => {
        if (analyticsInstance) {
          logEvent(analyticsInstance, "save_search", {
            user_id: userId,
            search_url: searchUrl,
          });
        }
      });
    });
  } catch (error) {
    console.error("Error saving search:", error);
    throw new Error("Failed to save search. Please try again.");
  }
};

// Saved Homes
export const checkIfHomeIsSaved = async (userId, zpid) => {
  try {
    const userRef = doc(db, "UserAccounts", userId);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const isSaved = userData.savedHomes && userData.savedHomes.includes(zpid);
      return isSaved;
    } else {
      console.error("No such document!");
      return false;
    }
  } catch (error) {
    console.error("Error checking if home is saved:", error);
    throw new Error("Failed to check if home is saved.");
  }
};

// Add Home to Saved Homes
export const addHomeToSavedHomes = async (userId, homeDetails) => {
  const userRef = doc(db, "UserAccounts", userId);
  await updateDoc(userRef, {
    savedHomes: arrayUnion(homeDetails), // homeDetails should be the full home object
  });
};

// Remove Home from Saved Homes
export const removeHomeFromSavedHomes = async (userId, homeDetails) => {
  const userRef = doc(db, "UserAccounts", userId);
  await updateDoc(userRef, {
    savedHomes: arrayRemove(homeDetails), // homeDetails should be the full home object
  });
};

// Recently Viewed Homes
export const saveRecentlyViewedHome = async (userId, listing) => {
  if (!listing || !listing.zpid) {
    console.error("Listing or listing.zpid is undefined.");
    return;
  }

  const userRef = doc(db, "UserAccounts", userId);

  try {
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      // Handle the case where the user document does not exist
      console.warn("User document does not exist, creating a new one.");
      await setDoc(userRef, {
        recentlyViewedHomes: [listing],
        // Add any other default fields for a new user document
      });
      return;
    }

    await runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userRef);
      if (!userDoc.exists()) {
        throw new Error("Document does not exist!");
      }

      let currentRecentlyViewed = userDoc.data().recentlyViewedHomes || [];
      currentRecentlyViewed = currentRecentlyViewed.filter(
        (item) => item.zpid !== listing.zpid
      );
      currentRecentlyViewed.unshift(listing);
      currentRecentlyViewed = currentRecentlyViewed.slice(0, 10);

      transaction.update(userRef, {
        recentlyViewedHomes: currentRecentlyViewed,
      });
    });
    // Log the recently viewed home event
    analytics.then((analyticsInstance) => {
      if (analyticsInstance) {
        logEvent(analyticsInstance, "view_home", {
          user_id: userId,
          home_id: listing.zpid,
        });
      }
    });
  } catch (error) {
    console.error("Transaction failed: ", error);
    throw new Error("Could not update recently viewed homes.");
  }
};

// Google Sign in (with event logging)
export const signInWithGoogle = async () => {
  try {
    const userCredential = await signInWithPopup(auth, googleProvider);
    console.log("Google sign-in successful", userCredential.user);
    localStorage.setItem("isLoggedIn", "true");

    // Log the login event
    analytics.then((analyticsInstance) => {
      if (analyticsInstance) {
        logEvent(analyticsInstance, "login", {
          method: "Google",
          user_id: userCredential.user.uid,
        });
      }
    });

    return userCredential;
  } catch (error) {
    if (error.code === "auth/popup-blocked") {
      alert(
        "It looks like your browser is blocking popups. Please allow popups for this website and try again."
      );
    } else if (error.code === "auth/popup-closed-by-user") {
      console.warn("Popup closed by user");
    } else {
      console.error("Error signing in with Google:", error);
    }
    throw error;
  }
};

// Email Sign in
export const logInWithEmailAndPassword = async (email, password) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    localStorage.setItem("isLoggedIn", "true");
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// Email Register (with event logging)
export const registerWithEmailAndPassword = async (
  name,
  email,
  password,
  userName,
  phoneNumber
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const userData = {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      userName: userName ? String(userName) : "",
      phoneNumber: phoneNumber ? String(phoneNumber) : "",
      isLandlordOrProfessional: false,
      joinDate: serverTimestamp(),
      photoURL: "",
      location: "",
      website: "",
    };
    await setDoc(doc(db, "UserAccounts", user.uid), userData);
    localStorage.setItem("isLoggedIn", "true");

    // Log the registration event
    analytics.then((analyticsInstance) => {
      if (analyticsInstance) {
        logEvent(analyticsInstance, "sign_up", {
          user_id: user.uid,
          method: "Email",
        });
      }
    });

    return res;
  } catch (err) {
    console.error("Error in registerWithEmailAndPassword:", err);
    throw err;
  }
};

// Password Reset
export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error("Failed to send password reset email: ", error);
    throw error;
  }
};

// Logout
export const logout = async () => {
  try {
    await signOut(auth);
    localStorage.removeItem("isLoggedIn");
  } catch (error) {
    console.error("Failed to log out:", error);
    throw error;
  }
};

// Profile Photo Upload and Update User's Profile Picture URL (with event logging)
export const uploadProfilePicture = async (file, userId) => {
  if (!file) {
    throw new Error("No file provided for upload");
  }

  const storageRef = ref(storage, `users/${userId}/Profile_Pictures/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // Optionally, report progress here
      },
      (error) => {
        reject(error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const userRef = doc(db, "UserAccounts", userId);
          await updateDoc(userRef, { photoURL: downloadURL });
          resolve(downloadURL);

          // Log the profile picture upload event
          analytics.then((analyticsInstance) => {
            if (analyticsInstance) {
              logEvent(analyticsInstance, "upload_profile_picture", {
                user_id: userId,
                file_name: file.name,
              });
            }
          });
        } catch (error) {
          reject(error);
        }
      }
    );
  });
};

// Update User Details in Firestore
export const updateUserDetails = async (userId, userDetails) => {
  const userRef = doc(db, "UserAccounts", userId);
  try {
    await updateDoc(userRef, userDetails);
  } catch (error) {
    console.error("Error updating user details:", error);
    throw error;
  }
};

// Update UserName
export const updateUserName = async (userId, newName) => {
  const userRef = doc(db, "UserAccounts", userId);
  try {
    await updateDoc(userRef, {
      userName: newName,
    });
  } catch (error) {
    console.error("Error updating Username in Firestore", error);
    throw error;
  }
};

export { auth, db, storage, app, doc, setDoc, getDoc };
