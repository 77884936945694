//App.mjs
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/App.mjs

/* global hj */

// **React and Related Imports**
import React, { Suspense, lazy, useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import SEO from "./seo/SEO.js";
// **Utility and Context Imports**
import axios from "axios";
import Swal from "sweetalert2";
import { SpeedInsights } from '@vercel/speed-insights/react';
import { useMapContext } from "./contexts/MapContext.js";
import { normalizeAddress } from "./utils/addressUtils.js";
import { fetchAdditionalDetailsByAddress } from "./FetchFunctions/fetchAdditionalDetailsByAddress.jsx";
import { fetchAdditionalDetails } from "./FetchFunctions/fetchAdditionalDetails.jsx";
import { getUserLocation } from "./utils/getUserLocation.js";
import { ScrollLockProvider } from "./contexts/scrollLockContext.js";
import { getCachedPolygonSearch, setCachedPolygonSearch, generateCacheKey } from "./utils/cache.js";
import { updateUrlWithSearchParams } from "./utils/urlUtils.js";
import { ALL_FILTER_PARAMS, FILTER_TYPES } from "./constants/filterParams.js";
// **Non-Lazy-Loaded Components**
import Navbar from "./components/navbar/NavBar.jsx";
import AuthModal from "./components/Login/AuthModal.jsx"; // Not wrapped with Suspense
import { ErrorProvider } from "./ErrorProvider.js";
import { TutorialProvider } from "./contexts/TutorialContext.js";
import { ViewModeProvider } from "./contexts/ViewModeContext.js";
import { app } from './components/firebase/Firebase.js';
import { isSupported, getAnalytics } from 'firebase/analytics';
import ErrorBoundary from "./utils/ErrorBoundry.jsx"; // Not lazy-loaded
import ProtectedRoute from "./routes/ProtectedRoute.jsx";
import { isAddress } from "./utils/addressUtils.js";

// **Lazy-Loaded Components**
const Home = lazy(() => import('./routes/Home.jsx'));
const LoadingSpinner = lazy(() => import("./components/loading/LoadingSpinner.jsx"));
const Listings = lazy(() => import('./routes/Listings.jsx'));
const Dashboard = lazy(() => import('./components/Login/Dashboard/Dashboard.jsx'));
const ProfileSettings = lazy(() => import('./components/Login/Dashboard/ProfileSettings.jsx'));
const PrivacyPolicy = lazy(() => import('./components/pages/companyResources/PrivacyPolicy.jsx'));
const Contact = lazy(() => import('./components/pages/companyResources/Contact.jsx'));
const AboutUs = lazy(() => import('./components/pages/companyResources/AboutUs.jsx'));
const SearchMortgageRates = lazy(() => import('./components/mortgageSection/SearchMortgageRates.jsx'));
const ListWithUs = lazy(() => import('./components/pages/sellerResources/ListWithUs.jsx'));
const HomeValue = lazy(() => import('./components/pages/sellerResources/HomeValue.jsx'));
const MortgageCalculator = lazy(() => import('./components/mortgageSection/MortgageCalculator.jsx'));
const RefinanceCalculator = lazy(() => import('./components/mortgageSection/RefinanceCalculator.jsx'));
const InvestmentCalculator = lazy(() => import('./components/pages/investorResources/InvestmentCalculator.jsx'));
const RenterResources = lazy(() => import('./components/pages/renterResources/RenterResources.jsx'));
const BuyerResources = lazy(() => import('./components/pages/buyerResources/BuyerResources.jsx'));
const SellerResources = lazy(() => import('./components/pages/sellerResources/SellerResources.jsx'));

const InvestorResources = lazy(() => import('./components/pages/investorResources/InvestorResources.jsx'));
const AgentFinderForm = lazy(() => import('./components/pages/companyResources/AgentFinderForm.jsx'));
const AgentFinderLanding = lazy(() => import('./components/pages/companyResources/AgentFinderLanding.jsx'));
const BookingPage = lazy(() => import('./components/pages/companyResources/BookingPage.jsx'));
const GetMoving = lazy(() => import('./components/pages/companyResources/GetMoving.jsx'));
const ResourcesLanding = lazy(() => import('./components/pages/ResourcesLanding.jsx'));
const Reset = lazy(() => import('./components/Login/Reset.js'));
const BlogList = lazy(() => import('./components/pages/blogs/BlogList.jsx'));
const BlogPostDetail = lazy(() => import('./components/pages/blogs/BlogPostDetail.jsx'));
const FeaturedListings = lazy(() => import('./components/navbar/FeaturedListings.jsx'));
const SearchLenders = lazy(() => import('./components/mortgageSection/SearchLenders.jsx'));
const RentalMarketTrends = lazy(() => import('./components/pages/investorResources/RentalMarketTrends.jsx'));
const SiteFooter = lazy(() => import('./components/footer/SiteFooter.jsx'));
const SitemapPage = lazy(() => import("./components/pages/companyResources/SitemapPage.jsx"));
const TermsOfService = lazy(() => import("./components/pages/companyResources/TermsOfService.jsx"));
const GoogleAuthCallback = lazy(() => import('./components/Login/GoogleAuthCallback.jsx'));
function App({ initialLoadingDone, startProgress, completeProgress, updateProgress, loading, progress }) {
  const [isAnalyticsInitialized, setIsAnalyticsInitialized] = useState(false);
  const [selectedZpid, setSelectedZpid] = useState(null);
  const [listings, setListings] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);
  const [urlTriggeredUpdate, setUrlTriggeredUpdate] = useState(false);

  const fetchTriggeredRef = useRef(false);
  const lastFetchedSearchRef = useRef(null);
  const [searchSource, setSearchSource] = useState(null); // 'urlLoad' or 'user'
  const abortControllerRef = useRef(null);

  const prevSearchTypeRef = useRef();
  const [searchCounter, setSearchCounter] = useState(0);
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [cachedPropertyDetails, setCachedPropertyDetails] = useState(null);

  const [shouldOpenModalOnLoad, setShouldOpenModalOnLoad] = useState(false);


  const handleUserSearch = (searchQuery) => {
      setSearchSource('user');
  
      // Use existing searchType from context, but update it if needed
      let newSearchType = searchType;
  
      if (searchQuery) {
        if (isAddress(searchQuery) && /\d/.test(searchQuery)) { 
            // ✅ Ensure search includes a number (e.g., 123 Main St)
            newSearchType = "address";
        } else if (!isAddress(searchQuery)) {  
            // ✅ Ensure cities are treated as locations
            newSearchType = "location";
        }
    }
    
  
      // Prepare updated filters, including existing filters
      const updatedFilters = {
          ...filters, // Include existing filters
          [FILTER_TYPES.PAGE]: '1', // Reset page to 1
      };
  
      // Update the URL with the new location and existing filters
      const newUrl = updateUrlWithSearchParams(
          updatedFilters,
          searchQuery,
          newSearchType,
          newSearchType === 'polygon' ? polygonCoordinates : null, // ✅ Correctly pass polygonCoords
          null, // zpid
          {} // existingFilters (we can pass an empty object here)
      );
  
      const currentUrl = window.location.pathname + window.location.search;
      if (newUrl !== currentUrl) {
          navigate(newUrl, { replace: true });
          setUrlTriggeredUpdate(true); // ✅ Trigger fetchListings
      }
  };


  useEffect(() => {
    setCurrentProgress(progress);
  }, [progress, setCurrentProgress]);
  useEffect(() => {
    async function setLocationForAnalyticsAndHotjar() {
      try {
        // Check if geolocation is supported by the browser
        if (navigator.geolocation) {
          const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

          // Function to fetch and send the location data
          const fetchAndSendLocation = async () => {
            try {
              const location = await getUserLocation();
              if (location) {
                // Hotjar location tagging
                hj('tagRecording', [
                  `city:${location.city}`,
                  `state:${location.state}`,
                  `country:${location.country}`,
                  `postalCode:${location.postalCode}`,
                  `timeZone:${location.timeZone}`,
                  `latitude:${location.latitude}`,
                  `longitude:${location.longitude}`,
                  `metroCode:${location.metroCode}`
                ]);

                // GA4 location detection
                if (typeof window.gtag === 'function') {
                  window.gtag('event', 'location_detected', {
                    'user_city': location.city,
                    'user_state': location.state,
                    'user_country': location.country,
                    'user_postal_code': location.postalCode,
                    'user_time_zone': location.timeZone,
                    'user_latitude': location.latitude,
                    'user_longitude': location.longitude,
                    'user_metro_code': location.metroCode,
                    'dimension1': location.city,
                    'dimension2': location.state,
                  });
                } else {
                  console.warn('gtag is not available');
                }
              } else {
                console.warn('Location data is not available');
              }
            } catch (error) {
              console.error('Location detection failed:', error);
            }
          };

          // If the permission is already granted, fetch the location immediately
          if (permissionStatus.state === 'granted') {
            fetchAndSendLocation();
          }

          // Handle permission changes: listen for when the user grants or denies permission
          permissionStatus.onchange = () => {
            if (permissionStatus.state === 'granted') {
              fetchAndSendLocation();
            } else if (permissionStatus.state === 'denied') {
              console.warn('Location permission denied by user');
            }
          };
        } else {
          console.warn('Geolocation is not supported by this browser');
        }
      } catch (error) {
        console.error('Location detection failed:', error);
      }
    }

    setLocationForAnalyticsAndHotjar();
  }, []);





  const [selectedListing, setSelectedListing] = useState(null);
  const [resetData, setResetData] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [filters, setFilters] = useState({
    minPrice: "",
    maxPrice: "",
    bedsMin: "",
    bedsMax: "",
    bathsMin: "",
    bathsMax: "",
    sqftMin: "",
    sqftMax: "",
    buildYearMin: "",
    buildYearMax: "",
    lotSizeMin: "",
    lotSizeMax: "",
    daysOn: "",
    daysOnZillow: "",
    status_type: "",
    home_type: "",
    sort: "Newest",
    isOpenHousesOnly: "",
    isComingSoon: "",
    isPendingUnderContract: "",
    isForSaleForeclosure: "",
    isPreForeclosure: "",
    saleByOwner: "",
    saleByAgent: "",
    isParkView: "",
    isWaterView: "",
    isWaterfront: "",
    isCityView: "",
    isMountainView: "",
    page: "1",
  });

  const defaultFilters = useMemo(() => ({
    minPrice: "",
    maxPrice: "",
    bedsMin: "",
    bedsMax: "",
    bathsMin: "",
    bathsMax: "",
    sqftMin: "",
    sqftMax: "",
    buildYearMin: "",
    buildYearMax: "",
    lotSizeMin: "",
    lotSizeMax: "",
    daysOn: "",
    daysOnZillow: "",
    status_type: "",
    home_type: "",
    sort: "Newest",
    isOpenHousesOnly: "",
    isComingSoon: "",
    isPendingUnderContract: "",
    isForSaleForeclosure: "",
    isPreForeclosure: "",
    saleByOwner: "",
    saleByAgent: "",
    isParkView: "",
    isWaterView: "",
    isWaterfront: "",
    isCityView: "",
    isMountainView: "",
    page: "1",
  }), []);


  const filtersRef = useRef(filters);

  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  const [totalResultCount, setTotalResultCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1); // Default to 1 if not defined

  const [useQuery, setUseQuery] = useState("");
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [activeTab] = useState(''); // default to 'login'

  const host = "zillow-com1.p.rapidapi.com";
  const key = process.env.REACT_APP_API_KEY_RAPID_API_KEY;


  const { polygonCoordinates, updatePolygonCoordinates, searchType, setSearchType } = useMapContext();

  const [filteredData, setFilteredData] = useState([]);

  const [appliedFilters, setAppliedFilters] = useState(filters);

  // At the top of your App component
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const zpid = queryParams.get('zpid');
  const address = queryParams.get('address');
  const addressTriggered = queryParams.get('addressTriggered');

  const [currentLocationSearch, setCurrentLocationSearch] = useState(location.search);

  useEffect(() => {
    if (location.search !== currentLocationSearch) {
      setCurrentLocationSearch(location.search);
    }
  }, [location.search]);

  useEffect(() => {
    // Parse filters from URL and update filters state
    const urlParams = new URLSearchParams(location.search);
    const updatedFilters = {};
    let newSearchQuery = useQuery;
    let newSearchType = searchType;

    urlParams.forEach((value, key) => {
      if (ALL_FILTER_PARAMS.includes(key)) {
        updatedFilters[key] = value;
      }
      if (key === 'location') {
        newSearchQuery = value;
        newSearchType = 'location';
      }
      if (key === 'polygon') {
        newSearchType = 'polygon';
        // You might need to parse polygon coordinates here
      }
      // Handle other search types if necessary
    });

    // Only update the filters state if there are filters in the URL
    if (Object.keys(updatedFilters).length > 0) {
      setFilters((prevFilters) => {
        // Update only if the filters have changed to avoid unnecessary re-renders
        if (JSON.stringify(prevFilters) !== JSON.stringify(updatedFilters)) {
          return { ...prevFilters, ...updatedFilters };
        } else {
          return prevFilters;
        }
      });
    }

    // Update useQuery and searchType if they have changed
    if (newSearchQuery !== useQuery) {
      setUseQuery(newSearchQuery);
    }

    if (newSearchType !== searchType) {
      setSearchType(newSearchType);
    }
  }, [location.search]);

  useEffect(() => {
    if (!isAnalyticsInitialized && navigator.onLine) { // ✅ Prevents execution when offline
      isSupported()
        .then((supported) => {
          if (!supported) {
            console.warn("Firebase Analytics is not supported.");
            return;
          }
          try {
            const analytics = getAnalytics(app);
            console.log("Firebase Analytics initialized successfully.");
          } catch (err) {
            console.error("Firebase analytics initialization failed:", err);
          }
        })
        .catch((err) => console.error("Error checking Firebase Analytics support:", err));
  
      setIsAnalyticsInitialized(true);
    } else if (!navigator.onLine) {
      console.warn("Skipping Firebase Analytics initialization. App is offline.");
    }
  }, [isAnalyticsInitialized]);
  

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const authParam = params.get('auth');

    if (authParam === 'login' || authParam === 'register') {
      setShowAuthModal(true);
    } else {
      setShowAuthModal(false);
    }
  }, [location]); // Monitor the entire location object, not just location.search


  const closeAuthModal = useCallback(() => {
    /*     console.log("Closing auth modal..."); */

    setShowAuthModal(false);

    setTimeout(() => {
      const params = new URLSearchParams(location.search);
      params.delete('auth');
      /*       console.log("Updated URL params:", params.toString()); */

      // Determine the new URL to navigate to
      let newUrl = `${location.pathname}${params.toString() ? `?${params.toString()}` : ''}`;

      // If the current path is /login or /register, redirect explicitly to home
      if (location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/dashboard') {
        /*         console.log("Redirecting to home page..."); */
        navigate('/', { replace: true });
      } else {
        /*         console.log("Navigating to:", newUrl); */
        navigate(newUrl, { replace: true });
      }
    }, 0);
  }, [navigate, location]);

  const isProduction = process.env.NODE_ENV === 'production';

  const apiConfig = {
    testing: {
      apiUrl: "https://zillow-com1.p.rapidapi.com/propertyExtendedSearch",
      headers: {
        "x-rapidapi-key": process.env.REACT_APP_API_KEY_RAPID_API_KEY,
        "x-rapidapi-host": "zillow-com1.p.rapidapi.com",
      },
    },
    production: {
      apiUrl: "/api/extendedSearch", // Your production server endpoint
      headers: {
        // Include any production-specific headers here, e.g., authentication tokens
        // "Authorization": `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
    },
  };

  const currentApiConfig = isProduction ? apiConfig.production : apiConfig.testing;


  const fetchListings = useCallback(
    async (options) => {
      // Abort previous fetch if it exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new AbortController and store it in the ref
      const controller = new AbortController();
      abortControllerRef.current = controller;
      const signal = controller.signal;

      const {
        searchQuery,
        polygonCoords = null,
        newSearchType,
        triggeredBy = "",
        updatedFilters = null,
      } = options;

      // Parameter validation
      if (
        (newSearchType === "polygon" && (!polygonCoords || polygonCoords.length === 0)) ||
        (newSearchType === "location" && (!searchQuery || searchQuery.trim() === ""))
      ) {
        Swal.fire({
          icon: "error",
          title: "Invalid Search",
          text: "Please provide valid search parameters and try again.",
        });
        return;
      }

      // Prevent duplicate calls or race conditions
      if (signal?.aborted) {
        return;
      }

      // **Set `isFetching` to true before starting the fetch**
      setIsFetching(true);
      startProgress();

      // Merge filters
      let effectiveFilters = {
        ...defaultFilters,
        ...(updatedFilters || {}),
      };

      // Only parse and merge URL parameters if updatedFilters are not provided
      if (!updatedFilters) {
        const urlSearchParams = new URLSearchParams(location.search);
        urlSearchParams.forEach((value, key) => {
          effectiveFilters[key] = value;
        });
      }

      // Set default status_type and page if not provided
      effectiveFilters.status_type = effectiveFilters.status_type || "ForSale";
      effectiveFilters.page = effectiveFilters.page || "1";

      // Handle polygon-specific logic
      let formattedPolygonCoords = null;

      if (typeof polygonCoords === "string" && polygonCoords.length > 0) {
        formattedPolygonCoords = polygonCoords;
      } else if (Array.isArray(polygonCoords) && polygonCoords.length > 0) {
        // Convert array to formatted string (assuming [lng, lat] pairs)
        formattedPolygonCoords = polygonCoords
          .map((coord) => `${coord[0]} ${coord[1]}`)
          .join(",");
      }

      // Validate formattedPolygonCoords
      if (newSearchType === "polygon" && !formattedPolygonCoords) {
        Swal.fire({
          icon: "error",
          title: "Invalid Search",
          text: "Formatted polygon coordinates are invalid.",
        });

        return;
      }

      // **Generate Comprehensive Cache Key**
      let cacheKey = null;
      if (newSearchType === "polygon" && formattedPolygonCoords) {
        cacheKey = generateCacheKey(formattedPolygonCoords, effectiveFilters);
      }

      // **Check Cache for Polygon Searches**
      if (newSearchType === "polygon" && cacheKey) {
        const cachedPolygonData = getCachedPolygonSearch(
          formattedPolygonCoords,
          effectiveFilters
        );

        // Modify condition to ensure we refetch if filters are changed.
        if (
          cachedPolygonData &&
          JSON.stringify(cachedPolygonData.filters) === JSON.stringify(effectiveFilters)
        ) {
          setListings(cachedPolygonData.results);

          // **Set isFetching to false since fetch is complete**
          setIsFetching(false);
          return; // Exit early if we can use the cached data and filters haven't changed.
        }
      }

      // **Set the search type in context**
      setSearchType(newSearchType); // Assuming setSearchType is from context/state

      setResetData(true); // Assuming setResetData is from state

      prevSearchTypeRef.current = newSearchType;

      // **Reset prevSearchTypeRef if switching from address to location search**
      if (newSearchType === "location" && prevSearchTypeRef.current === "address") {
        prevSearchTypeRef.current = ""; // Reset previous search type for a new location search
      }

      // Adjust effectiveFilters based on search type
      if (newSearchType === "polygon") {
        if (formattedPolygonCoords) {
          effectiveFilters.polygon = formattedPolygonCoords;
          delete effectiveFilters.address;
          delete effectiveFilters.location;
          // Update the MapContext's polygonCoordinates
          updatePolygonCoordinates(formattedPolygonCoords);
        }
      } else if (newSearchType === "location") {
        if (searchQuery) {
          // If searchQuery appears to be an address (contains a comma or digit), use 'address'
          if (isAddress(searchQuery)) {
            effectiveFilters.address = searchQuery;
          } else {
            effectiveFilters.location = searchQuery;
          }
          delete effectiveFilters.polygon;
        }
      }

      Object.keys(effectiveFilters).forEach((key) => {
        // Remove keys only if the value is an empty string, undefined, or null.
        if (
          effectiveFilters[key] === "" ||
          effectiveFilters[key] === undefined ||
          effectiveFilters[key] === null ||
          (effectiveFilters.status_type === "ForRent" &&
            [
              "saleByAgent",
              "saleByOwner",
              "isForSaleForeclosure",
              "isNewConstruction",
              "isComingSoon",
              "isAuction",
              "isPendingUnderContract",
              "otherListings",
            ].includes(key))
        ) {
          delete effectiveFilters[key];
        }
      });
      
      // Adjust params for the API request
      const params = { ...effectiveFilters };

      // Further adjust params based on search type
      if (newSearchType === "polygon" && formattedPolygonCoords) {
        params.polygon = formattedPolygonCoords;
        delete params.address;
        delete params.location;
      } else if (newSearchType === "location") {
        delete params.polygon;
      }

      // Remove any params that are empty strings
      Object.keys(params).forEach((key) => {
        if (params[key] === "") {
          delete params[key];
        }
      });

      try {
        let details;
        if (newSearchType === "address" && isAddress(searchQuery)) {
            details = await fetchAdditionalDetailsByAddress(
                normalizeAddress(searchQuery)
            );
        } else if (newSearchType === "zpid") {
            details = await fetchAdditionalDetails(searchQuery);
        } else {
        
          // **Environment-Specific API Call**
          const { apiUrl, headers } = currentApiConfig;

          const response = await axios.get(apiUrl, {
            params,
            signal,
            headers,
          });

          if (response.status !== 200) {
            throw new Error(`Server error: ${response.statusText}`);
          }

          const responseData = response.data;

          const totalResultCountFromResponse = responseData.totalResultCount;

          setTotalResultCount(totalResultCountFromResponse); // Assuming setTotalResultCount is from state

          const totalPagesFromResponse = responseData.totalPages;

          setTotalPages(totalPagesFromResponse); // Assuming setTotalPages is from state

          if (
            Array.isArray(responseData.props) &&
            responseData.props.length > 0
          ) {
            const validProps = responseData.props.filter(
              (property) =>
                property.latitude !== null && property.longitude !== null
            );

            // **Cache the results by the comprehensive cacheKey**
            if (newSearchType === "polygon" && cacheKey) {
              setCachedPolygonSearch(
                formattedPolygonCoords,
                effectiveFilters,
                validProps
              );
            }

            setListings(validProps); // Assuming setListings is from state

            validProps.forEach((_, i) => {
              const percentage = ((i + 1) / validProps.length) * 100;
              updateProgress(percentage); // Assuming updateProgress is from state
            });

            // **Set isFetching to false since fetch is complete**
            setIsFetching(false);
            return validProps;
          } else if (responseData.zpid) {
            details = responseData;
          }
        }

        if (details) {
          setListings([details]); // Assuming setListings is from state
          setSelectedListing(details); // Assuming setSelectedListing is from state

          const newUrl = updateUrlWithSearchParams(
            params,
            searchQuery,
            newSearchType,
            formattedPolygonCoords || undefined,
            details.zpid,
            defaultFilters
          ); // Ensure this function is correctly implemented
          const currentUrl = `${window.location.pathname}?${window.location.search}`;
          if (newUrl !== currentUrl) {
            navigate(newUrl, { replace: true });
          }
          // **Set isFetching to false since fetch is complete**
          setIsFetching(false);
          return [details];
        } else {
          setListings([]);
          Swal.fire({
            icon: "error",
            title: "No Results Found",
            text: "No properties found! Adjust your filters and try again, or search a new location.",
          });

          const newUrl = updateUrlWithSearchParams(
            params,
            searchQuery,
            newSearchType,
            formattedPolygonCoords || undefined,
            undefined,
            defaultFilters
          );

          const currentUrl = `${window.location.pathname}?${window.location.search}`;
          if (newUrl !== currentUrl) {
            navigate(newUrl, { replace: true });
          }
          // **Set isFetching to false since fetch is complete**
          setIsFetching(false);
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          // Optionally, handle other errors (e.g., show a notification)
        }
        setListings([]);

        const newUrl = updateUrlWithSearchParams(
          params,
          searchQuery,
          newSearchType,
          formattedPolygonCoords || undefined,
          undefined,
          defaultFilters
        );
        const currentUrl = `${window.location.pathname}?${window.location.search}`;
        if (newUrl !== currentUrl) {
          navigate(newUrl, { replace: true });
        }
        // **Set `isFetching` to false since fetch is complete**
        setIsFetching(false);
      } finally {
        fetchTriggeredRef.current = false;
        completeProgress();
      }
      setSearchCounter((prev) => prev + 1);
      setUserHasInteracted(false);
    },
    [
      filters,
      startProgress,
      updateProgress,
      completeProgress,
      defaultFilters,
      setSelectedListing,
      location.search,
      navigate,
      generateCacheKey,
      getCachedPolygonSearch,
      setCachedPolygonSearch,
      fetchAdditionalDetailsByAddress,
      fetchAdditionalDetails,
      normalizeAddress,
      updateUrlWithSearchParams,
      setListings,
      setTotalResultCount,
      setTotalPages,
      setSearchCounter,
      setUserHasInteracted,
      currentApiConfig, // Ensure this is included
    ]
  );


  const fetchDetails = useCallback(async (zpid, address) => {
    let details = null;

    if (zpid) {
      try {
        details = await fetchAdditionalDetails(zpid);
      } catch (error) {
        console.error(`Failed to fetch details by zpid: ${zpid}`, error);
        throw error; // Propagate the error for handling in useEffect
      }
    }

    if (!details && address) {
      try {
        details = await fetchAdditionalDetailsByAddress(address);
      } catch (error) {
        console.error(`Failed to fetch details by address: ${address}`, error);
        throw error; // Propagate the error for handling in useEffect
      }
    }

    if (!details) {
      console.warn("No details fetched from either zpid or address.");
      Swal.fire({
        title: 'Error!',
        html: 'Sorry, we had some trouble fetching the property details. Please try again later or contact support.',
        icon: 'error',
      });
    }

    return details;
  }, []); // No dependencies as it relies on stable functions

  // src/App.mjs

  useEffect(() => {
    // Only proceed if a URL-triggered update is required
    if (
      urlTriggeredUpdate &&
      !fetchTriggeredRef.current &&
      location.search !== lastFetchedSearchRef.current
    ) {
      fetchTriggeredRef.current = true; // Indicate that a fetch is in progress
      lastFetchedSearchRef.current = location.search; // Update the last fetched search

      const queryParams = new URLSearchParams(location.search);
      const zpid = queryParams.get('zpid');
      const address = queryParams.get('address');
      const locationParam = queryParams.get('location');
      const polygonCoords = queryParams.get('polygon');

      if (zpid || address) {
        // **Handle `zpid` or `address` Searches**

        startProgress(); // Start loading indicator

        fetchDetails(zpid, address)
          .then((details) => {
            if (details) {
              setListings([details]); // Display the single listing
              setSelectedListing(details); // Set as the selected listing
              setSelectedZpid(details.zpid); // Update the selected zpid
              setShouldOpenModalOnLoad(true); // Trigger modal display
            }
          })
          .catch((error) => {
            if (error.name === 'AbortError') {
              /* console.log("FetchDetails aborted"); */
            } else {
              console.error("Error fetching details:", error);
              Swal.fire('Error!', 'Failed to fetch property details.', 'error');
            }
          })
          .finally(() => {
            setUrlTriggeredUpdate(false); // Reset the URL-triggered update flag
            fetchTriggeredRef.current = false; // Reset the fetch flag
            setIsDataReady(true); // Indicate data readiness
            completeProgress(); // Complete loading indicator
          });

      } else if (locationParam || polygonCoords) {
        // **Handle `location` or `polygon` Searches**

        startProgress(); // Start loading indicator

        const searchQuery = locationParam || ''; // Ensure it's a string
        const searchType = locationParam ? 'location' : 'polygon';

        fetchListings({
          searchQuery,
          polygonCoords,
          newSearchType: searchType,
          triggeredBy: 'urlLoad',
          updatedFilters: filtersRef.current, // Use the ref to get the latest filters
        })
          .then((data) => {
            // Listings have been set within fetchListings
          })
          .catch((error) => {
            if (error.name === 'AbortError') {
              /* console.log("FetchListings aborted"); */
            } else {
              console.error("Error fetching listings:", error);
              Swal.fire('Error!', 'Failed to fetch listings.', 'error');
            }
          })
          .finally(() => {
            setUrlTriggeredUpdate(false); // Reset the URL-triggered update flag
            fetchTriggeredRef.current = false; // Reset the fetch flag
            setIsDataReady(true); // Indicate data readiness
            completeProgress(); // Complete loading indicator
          });

      } else {
        // **No Relevant Parameters Found**

        setUrlTriggeredUpdate(false); // Reset the URL-triggered update flag
        fetchTriggeredRef.current = false; // Reset the fetch flag
        setIsDataReady(true); // Indicate data readiness
        completeProgress(); // Complete loading indicator
      }
    }
  }, [urlTriggeredUpdate, location.search]);



  /*  useEffect(() => {
      console.log("Dependency Changes:");
      console.log("filters:", filters);
      console.log("polygonCoordinates:", polygonCoordinates);
      console.log("startProgress:", startProgress);
      console.log("updateProgress:", updateProgress);
      console.log("completeProgress:", completeProgress);
      console.log("defaultFilters:", defaultFilters);
      console.log("setSelectedListing:", setSelectedListing);
      console.log("useQuery:", useQuery);
      console.log("location.search:", location.search);
      console.log("navigate:", navigate);
      console.log("key:", key);
      console.log("host:", host);
    }, [
      filters,
      polygonCoordinates,
      startProgress,
      updateProgress,
      completeProgress,
      defaultFilters,
      setSelectedListing,
      useQuery,
      location.search,
      navigate,
      key,
      host,
    ]); */

  const handleClearFilters = useCallback(() => {
    setFilters(defaultFilters);
    setResetData(true);
  }, [defaultFilters]);

  useEffect(() => {
    if (resetData) {
      setListings([]);
      setFilteredData([]);
      setResetData(false);
    }
  }, [resetData, setFilteredData]);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
    }
  }, []);


  return (
    <ErrorBoundary>
      <ScrollLockProvider>
        <ViewModeProvider>
          <TutorialProvider>
            <ErrorProvider>
              {/* Header */}
              <header>
                <Navbar
                  closeAuthModal={closeAuthModal}
                  showAuthModal={showAuthModal}
                  setShowAuthModal={setShowAuthModal}
                />
                {showAuthModal && (
                  <AuthModal activeTab={activeTab} onClose={closeAuthModal} />
                )}
              </header>

              <SpeedInsights />
              {/* Main Content */}
              <main id="main-content">
                <div className="mainContent-container bg-white dark:bg-gradient-to-br from-black via-gray-900 to-indigo-950">
                  <Routes>
                    {/* Home Route */}
                    <Route
                      path="/"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Willamette Valley Real Estate Agents, Realtors | Spear Real Estate Group"
                              description="Leaders in the real estate marketplace. Search millions of for-sale and rental properties, compare home values, and connect with experienced local professionals at Spear Real Estate Group."
                              canonical="https://www.spearrealestategroup.com/"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="real estate, property, home, sale, buy, realtor, Willamette Valley"
                            />
                            <Home
                              handleUserSearch={handleUserSearch}
                              fetchListings={fetchListings}
                              listings={listings}
                              setSearchType={setSearchType}
                              filters={filters}
                              useQuery={useQuery}
                              setFilters={setFilters}
                              setAppliedFilters={setAppliedFilters}
                              handleClearFilters={handleClearFilters}
                              searchType={searchType}
                              setListings={setListings}
                              setFilteredData={setFilteredData}
                              fetchAdditionalDetails={fetchAdditionalDetails}
                              defaultFilters={defaultFilters}
                              updateUrlWithSearchParams={updateUrlWithSearchParams}
                              setUseQuery={setUseQuery}
                              appliedFilters={appliedFilters}
                              urlTriggeredUpdate={urlTriggeredUpdate}
                              setUrlTriggeredUpdate={setUrlTriggeredUpdate}
                              startProgress={startProgress}
                              completeProgress={completeProgress}
                              updateProgress={updateProgress}
                              loading={initialLoadingDone}
                            />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Secondary '/home' route */}
                    <Route
                      path="/home"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Willamette Valley Real Estate Agents, Realtors | Spear Real Estate Group"
                              description="Leaders in the real estate marketplace. Search millions of for-sale and rental properties, compare home values, and connect with experienced local professionals at Spear Real Estate Group."
                              canonical="https://www.spearrealestategroup.com/"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="real estate, property, home, sale, buy, realtor, Willamette Valley"
                            />
                            <Home
                              handleUserSearch={handleUserSearch}
                              fetchListings={fetchListings}
                              listings={listings}
                              setSearchType={setSearchType}
                              filters={filters}
                              useQuery={useQuery}
                              setFilters={setFilters}
                              setAppliedFilters={setAppliedFilters}
                              handleClearFilters={handleClearFilters}
                              searchType={searchType}
                              setListings={setListings}
                              setFilteredData={setFilteredData}
                              fetchAdditionalDetails={fetchAdditionalDetails}
                              defaultFilters={defaultFilters}
                              updateUrlWithSearchParams={updateUrlWithSearchParams}
                              setUseQuery={setUseQuery}
                              appliedFilters={appliedFilters}
                              urlTriggeredUpdate={urlTriggeredUpdate}
                              setUrlTriggeredUpdate={setUrlTriggeredUpdate}
                              startProgress={startProgress}
                              completeProgress={completeProgress}
                              updateProgress={updateProgress}
                              loading={initialLoadingDone}
                            />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Listings Route */}
                    <Route
                      path="/listings/:locationOrZpid?/:zpid?/"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <Listings
                            onSearch={handleUserSearch}
                            isFetching={isFetching}
                            setIsFetching={setIsFetching}
                            startProgress={startProgress}
                            completeProgress={completeProgress}
                            updateProgress={updateProgress}
                            loading={loading}
                            progress={progress}
                            shouldOpenModalOnLoad={shouldOpenModalOnLoad}
                            setShouldOpenModalOnLoad={setShouldOpenModalOnLoad}
                            setUserHasInteracted={setUserHasInteracted}
                            userHasInteracted={userHasInteracted}
                            queryParams={{ zpid, address, addressTriggered }}
                            selectedListing={selectedListing}
                            setSelectedListing={setSelectedListing}
                            selectedZpid={selectedZpid}
                            setSelectedZpid={setSelectedZpid}
                            cachedPropertyDetails={cachedPropertyDetails}
                            setCachedPropertyDetails={setCachedPropertyDetails}
                            isDataReady={isDataReady}
                            setIsDataReady={setIsDataReady}
                            urlTriggeredUpdate={urlTriggeredUpdate}
                            setUrlTriggeredUpdate={setUrlTriggeredUpdate}
                            updateUrlWithSearchParams={updateUrlWithSearchParams}
                            fetchListings={fetchListings}
                            listings={listings}
                            filters={filters}
                            setFilters={setFilters}
                            useQuery={useQuery}
                            setUseQuery={setUseQuery}
                            appliedFilters={appliedFilters}
                            setAppliedFilters={setAppliedFilters}
                            handleClearFilters={handleClearFilters}
                            searchType={searchType}
                            setSearchType={setSearchType}
                            totalResultCount={totalResultCount}
                            setTotalResultCount={setTotalResultCount}
                            totalPages={totalPages}
                            setTotalPages={setTotalPages}
                            setListings={setListings}
                            setFilteredData={setFilteredData}
                            fetchAdditionalDetails={fetchAdditionalDetails}
                            defaultFilters={defaultFilters}
                          />
                        </Suspense>
                      }
                    />

                    {/* Google Auth Callback Route */}
                    <Route
                      path="/auth/google/callback"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <GoogleAuthCallback />
                        </Suspense>
                      }
                    />

                    {/* Reset Route */}
                    <Route
                      path="/reset"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <Reset />
                        </Suspense>
                      }
                    />

                    {/* Login Route */}
                    <Route
                      path="login"
                      element={<AuthModal activeTab="login" onClose={closeAuthModal} />}
                    />

                    {/* Register Route */}
                    <Route
                      path="register"
                      element={<AuthModal activeTab="register" onClose={closeAuthModal} />}
                    />

                    {/* Featured Listings Route */}
                    <Route
                      path="/featured-listings"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <FeaturedListings />
                        </Suspense>
                      }
                    />

                    {/* Dashboard Routes */}
                    <Route
                      path="/dashboard"
                      element={
                        <ProtectedRoute>
                          <Suspense fallback={<LoadingSpinner />}>
                            <ProfileSettings to="/dashboard/profilesettings" />
                          </Suspense>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/dashboard/:tabName"
                      element={
                        <ProtectedRoute>
                          <Suspense fallback={<LoadingSpinner />}>
                            <Dashboard />
                          </Suspense>
                        </ProtectedRoute>
                      }
                    />

                    {/* Contact Route */}
                    <Route
                      path="/contact"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Contact Us - Spear Real Estate Group"
                              description="Get in touch with Spear Real Estate Group."
                              canonical="https://www.spearrealestategroup.com/contact"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="contact, real estate, Spear Real Estate Group"
                            />
                            <Contact />
                          </>
                        </Suspense>
                      }
                    />

                    {/* About Us Route */}
                    <Route
                      path="/about"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="About Us - Spear Real Estate Group"
                              description="Learn more about Spear Real Estate Group."
                              canonical="https://www.spearrealestategroup.com/about"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="about us, real estate, Spear Real Estate Group"
                            />
                            <AboutUs />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Privacy Policy Route */}
                    <Route
                      path="/policy"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Privacy Policy - Spear Real Estate Group"
                              description="Read our privacy policy."
                              canonical="https://www.spearrealestategroup.com/policy"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="privacy policy, real estate, Spear Real Estate Group"
                            />
                            <PrivacyPolicy />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Terms of Service Route */}
                    <Route
                      path="/terms"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Terms of Service - Spear Real Estate Property Finder"
                              description="Read our terms of service."
                              canonical="https://www.spearrealestategroup.com/terms"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="terms of service, real estate, Spear Real Estate Group"
                            />
                            <TermsOfService />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Search Mortgage Rates Route */}
                    <Route
                      path="/rate-search"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Search Mortgage Rates - Spear Real Estate Group"
                              description="View current mortgage rates and compare lenders."
                              canonical="https://www.spearrealestategroup.com/rate-search"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="mortgage rates, lenders, real estate, Spear Real Estate Group"
                            />
                            <SearchMortgageRates />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Mortgage Calculator Route */}
                    <Route
                      path="/mortgage-calculator"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Mortgage Calculator - Spear Real Estate Group"
                              description="Calculate your mortgage payments with our mortgage calculator."
                              canonical="https://www.spearrealestategroup.com/mortgage-calculator"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="mortgage calculator, real estate, Spear Real Estate Group"
                            />
                            <MortgageCalculator />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Search Lenders Route */}
                    <Route
                      path="/search-lenders"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Search Lenders - Spear Real Estate Group"
                              description="Find the best lenders with Spear Real Estate Group."
                              canonical="https://www.spearrealestategroup.com/search-lenders"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="search lenders, real estate, Spear Real Estate Group"
                            />
                            <SearchLenders />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Refinance Calculator Route */}
                    <Route
                      path="/refinance"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Refinance Calculator - Spear Real Estate Group"
                              description="Calculate your refinance options with our refinance calculator."
                              canonical="https://www.spearrealestategroup.com/refinance"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="refinance calculator, real estate, Spear Real Estate Group"
                            />
                            <RefinanceCalculator />
                          </>
                        </Suspense>
                      }
                    />


                    {/* Agent Finder Form Route */}
                    <Route
                      path="/agent-finder"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Find a Top Buyer Agent"
                              description="Find the best real estate agents with our agent finder."
                              canonical="https://www.spearrealestategroup.com/agent-finder"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="find agent, real estate agents, Spear Real Estate Group"
                            />
                            <AgentFinderForm />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Agent Finder Landing Route */}
                    <Route
                      path="/agent-landing"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Find a Top Buyer Agent"
                              description="Find the best real estate agents with our agent finder landing page."
                              canonical="https://www.spearrealestategroup.com/agent-landing"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="find agent, real estate agents, Spear Real Estate Group"
                            />
                            <AgentFinderLanding />
                          </>
                        </Suspense>
                      }
                    />

                    {/* List With Us Route */}
                    <Route
                      path="/list-with-us"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="List with Us - Spear Real Estate Group"
                              description="List your home with Spear Real Estate Group."
                              canonical="https://www.spearrealestategroup.com/list-with-us"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="list with us, list home, real estate, Spear Real Estate Group"
                            />
                            <ListWithUs />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Home Value Route */}
                    <Route
                      path="/home-value"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Home Value - Spear Real Estate Group"
                              description="Find out the value of your home."
                              canonical="https://www.spearrealestategroup.com/home-value"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="home value, real estate, Spear Real Estate Group"
                            />
                            <HomeValue />
                          </>
                        </Suspense>
                      }
                    />



                    {/* Rental Market Trends Route */}
                    <Route
                      path="/rental-market-trends"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Rental Market Trends - Spear Real Estate Group"
                              description="Stay updated with the latest rental market trends."
                              canonical="https://www.spearrealestategroup.com/rental-market-trends"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="rental market trends, rentals, real estate, Spear Real Estate Group"
                            />
                            <RentalMarketTrends />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Investment Calculators Route */}
                    <Route
                      path="/investment-calculators"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Investment Calculators - Spear Real Estate Group"
                              description="Calculate your investment options with our calculators."
                              canonical="https://www.spearrealestategroup.com/investment-calculators"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="investment calculators, investments, real estate, Spear Real Estate Group"
                            />
                            <InvestmentCalculator />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Blog List Route */}
                    <Route
                      path="/blog"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <BlogList />
                        </Suspense>
                      }
                    />

                    {/* Blog Post Detail Route */}
                    <Route
                      path="/blog/:postId"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <BlogPostDetail />
                        </Suspense>
                      }
                    />

                    {/* Sitemap Route */}
                    <Route
                      path="/sitemap"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Sitemap - Spear Real Estate Group"
                              description="Explore the sitemap of Spear Real Estate Group to find all available pages and resources."
                              canonical="https://www.spearrealestategroup.com/sitemap"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="sitemap, real estate, Spear Real Estate Group"
                            />
                            <SitemapPage />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Schedule Route */}
                    <Route
                      path="/schedule"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Book a Meeting - Spear Real Estate Group"
                              description="Schedule a meeting with us using our online booking system."
                              canonical="https://www.spearrealestategroup.com/schedule"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg" // Updated ogImage
                              keywords="schedule meeting, booking, real estate, Spear Real Estate Group"
                            />
                            <BookingPage />
                          </>
                        </Suspense>
                      }
                    />
                    {/* Resources Landing Page */}
                    <Route
                      path="/resources"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Resources - Spear Real Estate Group"
                              description="Explore resources for buyers, sellers, investors, and renters. Find expert guides, moving assistance, and real estate insights."
                              canonical="https://www.spearrealestategroup.com/resources"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                              keywords="real estate resources, buyer guide, seller guide, investor guide, renter resources, Spear Real Estate Group"
                            />
                            <ResourcesLanding />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Seller Resources */}
                    <Route
                      path="/seller-resources"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Seller Resources - Spear Real Estate Group"
                              description="Find resources for home sellers."
                              canonical="https://www.spearrealestategroup.com/seller-resources"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                              keywords="seller resources, home sellers, real estate, Spear Real Estate Group"
                            />
                            <SellerResources />
                          </>
                        </Suspense>
                      }
                    />
                    <Route
                      path="/resources/seller-resources"
                      element={
                        <Navigate replace to="/seller-resources" />
                      }
                    />

                    {/* Buyer Resources */}
                    <Route
                      path="/buyer-resources"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Buyer Resources - Spear Real Estate Group"
                              description="Find resources for home buyers."
                              canonical="https://www.spearrealestategroup.com/buyer-resources"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                              keywords="buyer resources, home buyers, real estate, Spear Real Estate Group"
                            />
                            <BuyerResources />
                          </>
                        </Suspense>
                      }
                    />
                    <Route
                      path="/resources/buyer-resources"
                      element={
                        <Navigate replace to="/buyer-resources" />
                      }
                    />

                    {/* Renter Resources */}
                    <Route
                      path="/renter-resources"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Renter Resources - Spear Real Estate Group"
                              description="Find resources for renters."
                              canonical="https://www.spearrealestategroup.com/renter-resources"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                              keywords="renter resources, renters, real estate, Spear Real Estate Group"
                            />
                            <RenterResources />
                          </>
                        </Suspense>
                      }
                    />
                    <Route
                      path="/resources/renter-resources"
                      element={
                        <Navigate replace to="/renter-resources" />
                      }
                    />

                    {/* Investor Resources */}
                    <Route
                      path="/investor-resources"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Investor Resources - Spear Real Estate Group"
                              description="Find resources for investors."
                              canonical="https://www.spearrealestategroup.com/investor-resources"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                              keywords="investor resources, real estate investment, Spear Real Estate Group"
                            />
                            <InvestorResources />
                          </>
                        </Suspense>
                      }
                    />
                    <Route
                      path="/resources/investor-resources"
                      element={
                        <Navigate replace to="/investor-resources" />
                      }
                    />

                    {/* Get Moving */}
                    <Route
                      path="/get-moving"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Get Moving with Ease - Spear Real Estate Group"
                              description="Let us handle your entire move from start to finish. Trusted movers, contractors, and home repair professionals for a stress-free experience."
                              canonical="https://www.spearrealestategroup.com/get-moving"
                              ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                              keywords="moving services, movers, contractors, home repairs, stress-free move, Spear Real Estate Group"
                            />
                            <GetMoving />
                          </>
                        </Suspense>
                      }
                    />
                    <Route
                      path="/resources/get-moving"
                      element={
                        <Navigate replace to="/get-moving" />
                      }
                    />


                  </Routes>
                  <footer>
                    <Suspense fallback={<LoadingSpinner />}>
                      <SiteFooter />
                    </Suspense>
                  </footer>
                </div>
              </main>

              {/* Footer */}
              {/* (Already included inside <main> as <SiteFooter />) */}
            </ErrorProvider>
          </TutorialProvider>
        </ViewModeProvider>
      </ScrollLockProvider>
    </ErrorBoundary>
  );
}

export default App;